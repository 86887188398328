html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body {
  font-size: 1rem;
}
body,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  line-height: 1.5em;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
}
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}
h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 14px;
  margin: 0 0 10px;
}

p.nomargin {
    margin: 0px;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
body {
  background-color: #b3b3b3;
  color: $black-color;
  margin: 0;
  font-size: 1rem;
  text-align: left;
}

legend {
  border-bottom: 0;
}

// Prevent highlight on mobile
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  &:focus {
    outline: 0;
  }
}

a {
  color: $link-color;
  text-decoration: none;
  background-color: transparent;
  &:hover,
  &:focus {
    color: darken($link-color, 5%);
    text-decoration: none;
  }
}

label {
  font-size: 14px;
  line-height: 1.42857;
  color: $checkboxes-text-color;
  font-weight: 400;
}
small {
  font-size: 75%;
  color: #777;
  font-weight: 400;
}
img {
  vertical-align: middle;
  border-style: none;
}
form {
  margin-bottom: 1.125rem;
}

ul.Pagination-pagination-306 {
    text-align: center;
    float: right;
    margin-top: 50px;
    /* width: 100px; */
}

.MuiGrid-container-195.MuiGrid-spacing-xs-24-219 h4, .MuiGrid-container-195.MuiGrid-spacing-xs-24-219 p {
    color: #3C4858;
    font-weight: 300;
}

p.login-helper {
    text-align: center;
    padding: 20px 20px;
}

.Pagination-pagination-326 {
    margin: 50px 0 0px 0;
}

.SectionExamples-section-207 {
    padding-bottom: 140px !important;
}

.Parallax-parallax-204 {
    height: 30vh !important;
    min-height: 400px !important;
    background: #727272;
}

ul.Pagination-pagination-380 {
    margin: 50px 0px;
}

.ProfilePage-container-1 {
    padding-bottom: 100px;
}

form.TextFields-container-368 {
    float: right;
}

.MuiFormControl-marginNormal-305 {
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.TextFields-textField-369 {
    margin-right: 0px;
}
